import React from 'react';
import Helmet from "react-helmet";
import projectThumb from '../../assets/images/project-thumb.png';

function ProjectHomeRtl({ className }) {
    return (
        <>
            <section className={`appie-project-area pb-100 ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="appie-project-box wow animated slideInUp"
                                data-wow-duration="1000ms"
                                data-wow-delay="0ms"
                            >
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="appie-project-content text-right"
                                        id="register">
                                            <h3 className="title">הצטרפו לרשימת המתנה</h3>
                                            <p>יהיו הראשונים להשתמש באפליקציה לפני כולם. מבטיחים לא לחפור.</p>
                                            {/* <form action="#">
                                                <div id="register" className="input-box mt-30">
                                                    <input type="text" placeholder="דואר אלקטרוני" />
                                                    <button type="button">הירשם</button>
                                                </div>
                                            </form> */}
                                            <div
                id="getWaitlistContainer"
                data-waitlist_id="16276"
                data-widget_type="WIDGET_3"
            ></div>
            <Helmet>
                <link
                rel="stylesheet"
                type="text/css"
                href="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.css"
                />
                <script src="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.js"></script>
            </Helmet>
                                            <br />
                                            <br />
                                            <br />
                                        </div>
                                    </div>
                                </div>
                                <div className="appie-project-thumb">
                                    <img src={projectThumb} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ProjectHomeRtl;
